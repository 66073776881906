import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Volumes/casesens/Users/matteoricci/Work/MaterialSolutions/Blog/materialx-blog/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
import { SEO } from "gatsby-theme-catalyst-core";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="404: Not found" mdxType="SEO" />
    <h1 {...{
      "id": "not-found"
    }}>{`NOT FOUND`}</h1>
    <p>{`You hit a page that does not exist!`}</p>
    <p><a parentName="p" {...{
        "href": "https://materialx.co.uk/"
      }}>{`head back home`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      